import React from 'react';
import { theme } from '@tuunetech/tuune-components';

//eslint-disable-next-line @typescript-eslint/no-var-requires
const { amplitudeDefaultConfig } = require('../analytics/amplitudeConfig');

const ANALYTICS = {
  name: 'analytics',
  label: 'Analytics',
  description: `We use some cookies to collect information about how people are using our Services.
                  For example, these cookies allow us to know which pages are visited the most often, how people are moving
                  from one link to another and if they get error messages from certain pages. Overall, these cookies provide
                  us with analytical information about how our Services are performing and how we can improve them.`,
  cookies: ['_ga', '_gid', '_gat*', 'amp*'],
  vendors: [
    {
      name: 'Google',
      url: 'https://policies.google.com/technologies/types?hl=en-US',
      description: 'Google Analytics',
    },
  ],
  onAccept: function () {
    window.dataLayer.push({
      civic_cookies_analytics: 'consent_given',
      event: 'analytics_consent_given',
    });

    if (typeof window.amplitude === 'object') {
      window.amplitude
        .getInstance()
        .init(process.env.GATSBY_AMPLITUDE_API_KEY, '', {
          ...amplitudeDefaultConfig,
          disableCookie: false,
        });
    }
  },
  onRevoke: function () {
    window.dataLayer.push({
      civic_cookies_analytics: 'consent_revoked',
      event: 'analytics_consent_revoked',
    });

    if (typeof window.amplitude === 'object') {
      window.amplitude
        .getInstance()
        .init(process.env.GATSBY_AMPLITUDE_API_KEY, '', {
          ...amplitudeDefaultConfig,
          disableCookie: true,
        });
    }
  },
};

const MARKETING = {
  name: 'marketing',
  label: 'Marketing',
  description: `These show us which products you’ve been looking at so we can show you ads you might like later.`,
  cookies: ['fr'],
  vendors: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/policies/cookies/',
      description: 'Facebook',
    },
  ],
  onAccept: function () {
    window.dataLayer.push({
      civic_cookies_marketing: 'consent_given',
      event: 'marketing_consent_given',
    });
  },
  onRevoke: function () {
    window.dataLayer.push({
      civic_cookies_marketing: 'consent_revoked',
      event: 'marketing_consent_revoked',
    });
  },
};

const optionalCookies = [];

if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
  // respect do not track option
  if (!(navigator.doNotTrack == '1' || window.doNotTrack == '1')) {
    optionalCookies.push(ANALYTICS);
    optionalCookies.push(MARKETING);
  }
}

export const CIVIC_COOKIE_CONFIG = {
  apiKey: process.env.GATSBY_CIVIC_API_KEY,
  product: 'PRO',
  optionalCookies: optionalCookies,
  excludedCountries: 'all',
  mode: 'CCPA',

  statement: {
    description: 'For more information visit our',
    name: 'Privacy Policy',
    url: '/privacy-policy',
    updated: '22/10/2021',
  },
  ccpaConfig: {
    description:
      'This may include the website or our third-party tools processing personal data. In the case of your personal data, you may opt out of such activity by using the buttons below. To find out more about the categories of personal information collected and the purposes for which such information will be used, please refer to our',
    name: 'Privacy Policy',
    url: '/privacy-policy',
    updated: '22/10/2021',
  },

  initialState: 'notify',
  position: 'LEFT',
  theme: 'DARK',
  notifyDismissButton: false,
  branding: {
    fontFamily: theme.typography.fontFamily,
    fontColor: theme.palette.primary.contrastText,
    notifyFontColor: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    alertBackground: theme.palette.secondary.main,
  },
};

const CIVIC_SCRIPT = (
  <script
    key="civic_source"
    src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
  />
);

const GOOGLE_TAG_MANAGER_SCRIPT = !!process.env.GATSBY_ENV &&
  ['prod', 'staging'].includes(process.env.GATSBY_ENV) && (
    <script key="gtm_script">
      {`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
       new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
       j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
       'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
       })(window,document,'script','dataLayer','GTM-5SWSRSL');
     `}
    </script>
  );

const GOOGLE_DATA_LAYER_INIT_SCRIPT = !!process.env.GATSBY_ENV &&
  ['prod', 'staging'].includes(process.env.GATSBY_ENV) && (
    <script key="data_layer_init">
      {`
      window.dataLayer = window.dataLayer || [];
    `}
    </script>
  );

const KLAVIYO_SIGNUP_FORM_SCRIPT = (
  <script
    async
    key="klaviyo"
    type="text/javascript"
    src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VK4UZP"
  />
);

const CONVERT_FLOW_QUIZ_SCRIPT = (
  <script
    async
    key="convertflow"
    type="text/javascript"
    src="https://js.convertflow.co/production/websites/39168.js"
  />
);

export const COOKIE_SCRIPTS = [
  CIVIC_SCRIPT,
  GOOGLE_DATA_LAYER_INIT_SCRIPT,
  GOOGLE_TAG_MANAGER_SCRIPT,
  KLAVIYO_SIGNUP_FORM_SCRIPT,
  CONVERT_FLOW_QUIZ_SCRIPT,
];
