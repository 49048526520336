import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle``;

export const GradientOrSolidBgCss = css<{
  $gradient?: string;
  $bgColor: string;
}>`
  background-color: ${({ $bgColor }) => $bgColor};
  background-image: ${({ $gradient }) =>
    $gradient ? `linear-gradient(${$gradient})` : undefined};
`;

export const PrimaryButtonColorCss = css`
  .MuiButton-containedPrimary {
    &,
    &:hover {
      background-color: #613ac7;
      color: #ffffff;
    }
  }

  .MuiButton-textPrimary {
    color: #613ac7;
  }

  .MuiButton-outlinedPrimary {
    color: #613ac7;
    border-width: 2px;
  }
`;

export const YellowButtonColorCss = css`
  .MuiButton-containedPrimary {
    &,
    &:hover {
      background-color: #fedeb3;
      color: #000000;
    }
  }
`;
