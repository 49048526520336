import React, { useEffect } from 'react';
import { analytics } from './analytics';
import { AnalyticsContext } from './context';

export const AnalyticsProvider: React.FC = ({ children }) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'optimize.activate' });
  }, []);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
