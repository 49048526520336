const amplitudeDefaultConfig = {
  saveEvents: true,
  includeFbclid: true,
  includeGclid: true,
  includeReferrer: true,
  includeUtm: true,
  sameSiteCookie: 'Lax',
  trackingOptions: {
    ip_address: false,
    city: false,
    dma: false,
  },
};

module.exports = {
  amplitudeDefaultConfig,
};
