import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { MenuItem, Select } from '@material-ui/core';
import { ThemeProvider } from '@tuunetech/tuune-components';
import '@fontsource/poppins';
import { GlobalStyle } from './GlobalStyle';
import { CookieWrapper } from './cookies/CookieWrapper';

export const VERSIONS: { [key: string]: string } = {
  A: 'A',
  B: 'B',
};

const StyledSelect = styled(Select)`
  position: fixed !important;
  z-index: 1000;
  bottom: 5px;
  left: 5px;
`;

export const VersionContext = React.createContext(VERSIONS.A);

const VersionProvider: React.FC = ({ children }) => {
  const [version, setVersion] = useState(VERSIONS.A);

  useEffect(() => {
    const buildV = process.env.GATSBY_BUILD;
    if (buildV) {
      // use version from build script
      // and fallback to A in case presented but not in the right format
      setVersion(VERSIONS[buildV] || VERSIONS.A);
    }
  }, []);

  const isDevMode = process.env.GATSBY_ENV === 'develop';

  return (
    <VersionContext.Provider value={version}>
      {/* Allow switch theme manually in dev mode */}
      {isDevMode && (
        <StyledSelect
          variant="filled"
          value={version}
          onChange={e => setVersion(e.target.value as string)}
        >
          <MenuItem value={VERSIONS.A}>Version A</MenuItem>
          <MenuItem value={VERSIONS.B}>Version B</MenuItem>
        </StyledSelect>
      )}
      {children}
    </VersionContext.Provider>
  );
};

export const wrapRootElement: React.FC<{
  element: ReactNode;
}> = ({ element }) => {
  return (
    <CookieWrapper>
      <PrismicPreviewProvider>
        <VersionProvider>
          <ThemeProvider>
            <GlobalStyle />
            {element}
          </ThemeProvider>
        </VersionProvider>
      </PrismicPreviewProvider>
    </CookieWrapper>
  );
};
