// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ccw-tsx": () => import("./../../../src/pages/ccw.tsx" /* webpackChunkName: "component---src-pages-ccw-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-early-access-tsx": () => import("./../../../src/pages/early-access.tsx" /* webpackChunkName: "component---src-pages-early-access-tsx" */),
  "component---src-pages-hipaa-authorization-to-use-and-disclose-phi-tsx": () => import("./../../../src/pages/hipaa-authorization-to-use-and-disclose-phi.tsx" /* webpackChunkName: "component---src-pages-hipaa-authorization-to-use-and-disclose-phi-tsx" */),
  "component---src-pages-informed-consent-tsx": () => import("./../../../src/pages/informed-consent.tsx" /* webpackChunkName: "component---src-pages-informed-consent-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-privacy-policy-app-tsx": () => import("./../../../src/pages/privacy-policy-app.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-app-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-policy-uk-tsx": () => import("./../../../src/pages/privacy-policy-uk.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-uk-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-terms-and-conditions-uk-tsx": () => import("./../../../src/pages/terms-and-conditions-uk.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-uk-tsx" */),
  "component---src-pages-waitlist-tsx": () => import("./../../../src/pages/waitlist.tsx" /* webpackChunkName: "component---src-pages-waitlist-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

