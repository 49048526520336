import { logAmplitudeEvent } from './amplitude';
import { ENHANCED_ECOMMERCE_EVENT } from './constants';
import { logGTMEvent } from './gtm';
import { Analytics, AnalyticsTrackData, EcommerceProduct } from './types';

export const analytics: Analytics = {
  track: (
    event: string | ENHANCED_ECOMMERCE_EVENT,
    data: AnalyticsTrackData,
  ): void => {
    logAmplitudeEvent(event, data);
    logGTMEvent(event, data);
  },

  logEnhancedEcommerceEvent: (
    event: ENHANCED_ECOMMERCE_EVENT,
    product: EcommerceProduct,
  ): void => {
    window.dataLayer = window.dataLayer || [];
    // Clear the previous ecommerce object
    window.dataLayer.push({
      ecommerce: null,
    });
    window.dataLayer.push({
      event,
      ecommerce: {
        items: [product],
      },
    });
  },
};
